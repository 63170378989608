.Hjem {
   
    /* background-position: center center; This property centers the background image */
    /* background-repeat: no-repeat; This property ensures that the background image does not repeat */
    /* This property sets the height of the background to 100% of the viewport height */
    /* padding: 10px; */
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    
  }

h1{
    /* padding: 0px 55% 0px 50px; */
    font-size: 50pt;
    color: black;
}

p{
    /* padding: 0px 55% 0px 50px; */
    color: black;
    
}   

#attention h1 {
    padding: 0px;
}

#attention{
  position: relative;
    background-image: url('/public/images/Frontpage.jpg'); /* alt("Rikke Kirkegaard Diætist Aarhus C Spiseforstyrrelse"); /* Replace 'path_to_your_image.jpg' with the actual path to your background image */
    background-position: top right;
    background-size: cover; /* This property ensures that the background image covers the entire element */
    background-repeat: no-repeat;
    height: 400px;
    /* max-height: 800px; */
    /* min-height: 500px;
    max-height: 500px;  */
    color: #F5F5DC; /* Set the text color to contrast with the background */
    /* padding-top: 20px; */
    padding: 10px 55% 10% 50px;
}

#offer{
    background-color: #b5ab9e;
    padding: 10px 55% 10px 50px;
    height: 250px; 
    margin: 50px;
    border-radius: 10pt;
}

#more{
    background-color: #b5ab9e;
    height: 250px; 
    padding: 10px 55% 10px 50px;
    margin: 50px;
    border-radius: 10pt;
}

/* Mobile devices */
@media /*(min-width: 320px) and*/ (max-width: 480px) {
    h1{
      font-size: 20pt;
      margin-top: 60px;
    }
    
    h3{
      font-size: large;
      margin: 20px 0 25px 0;
    }
    
    p{
      font-size: x-small;
    }
    
    #headermenu{
      display: none;
    }
    
  }
  
  /* iPads, Tablets */
  @media (min-width: 481px) and (max-width: 768px) {
  
    #headermenu{
      display: none;
    }
  
    h1{
      font-size: 30pt;
      margin-top: 60px;
    }
    /**
      * Header (Dissapering -> Make a burger menu)
      * Home (Header animation is too big -> Decrease size)
      * Projects (Height issues maybe, Carrousel issues being too big and not centered)
      * Projects
      ** Map (Video being fixed size as too big, Code Section overflow to right )
      ** All of the other (Code Section overflow to right)
      * Education (Semester/subject blocks are fixed sizes -> try try rem instead of px, height)
      * Contact (Padding issues between text and map)
      */
  }
  
  /* Small screens, laptops */
  @media (min-width: 769px) and (max-width: 1024px) {
    #mobile-menu{
      display: none;
    }
    /**
      * Projects
      ** Map (Video not centered, Code Section overflow to right)
      ** All of the other (Code Section overflow to right)
      * Education
      */
  }
  
  /* Desktops, large screens */
  @media (min-width: 1025px) and (max-width: 1200px) {
    /*Everything appears to work fine*/  
    #mobile-menu{
      display: none;
    }
  }
  
  /* Extra large screens, TV */
  @media (min-width: 1201px) {
    /*Everything appears to work fine*/
    #mobile-menu{
      display: none;
    }
  }