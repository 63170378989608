.header {
    /* background-color: #f8f8f8; */
    /* padding: 10px 10px;
    height: 10vh; */
    /* align-items: center;
    display: grid; */
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* box-shadow: inset; */
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0)
        rgba(0, 0, 0, 0.3)
    );
}

/* nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
} */

#hederlogo{
    justify-content: left;
}   

#headermenu{
    justify-content: right;
}


nav a {
    text-decoration: none;
    color: Black;
    margin: 0 15px;
    font-weight: bold;
} 

nav p:hover{
    color: #b5ab9e;
}

#headermenu p.active {
    color: #b5ab9e;
}

header p:hover {
    color: #b5ab9e;
    font-size: 12pt;
    animation: scaleAnimation 0.3s ease forwards; /* Added scale animation on hover */
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

header p{
    display: table-row;
    position: relative;
    transition: 0.3s;
}
  
#Logo {
    font-size: 24px;
    margin: 0;
    display: table-row;
    /* width: 25%; */
}


  