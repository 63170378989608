
/* secondary color: #b5ab9e */


/* General APP style config */
.App {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*Header css config in Header.css*/

/* CSS styling for content between Header/Navbar and Footer */
main {
  /* padding: 20px 0; */
}

h1 {
  /* padding-left: 50px; */
}
/* CSS styiling for Footer */
#footerDiv {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  background-color: #b5ab9e;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}
#footerDiv span {
  color: white;
}
#footerDiv a {
  color: white;
}

a {
  text-decoration: none;
  color: black;
}

footer {
  text-align: center;
  width: 100%;
}

footer p {
  margin: 0;
}

.card {
  color: white;
  background-color: #b5ab9e;
}



/* Animations ???
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

Media Querries
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* Mobile devices */
@media /*(min-width: 320px) and*/ (max-width: 480px) {
  h1{
    font-size: 20;
    margin-top: 60px;
  }
  
  h3{
    font-size: large;
    margin: 20px 0 25px 0;
  }
  
  p{
    font-size: x-small;
  }
  
  nav{
    margin: auto;
    text-align: center;
  }

  #Logo{
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .headerlogo{
    padding: 0;
    width: auto;
  }

  #headermenu{
    display: none;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(181, 171, 158, 0.8); /* Adjust the last value for transparency */
    z-index: 0; 
}

#attention{
  min-height: 500px;
}
.attentionContent{
  position: relative;  
  color: rgb(245, 245, 220);
  z-index: 1; 
  padding-left: 0;
}
  
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {

  h1{
    font-size: 20;
    margin-top: 60px;
  }
  
  h3{
    font-size: large;
    margin: 20px 0 25px 0;
  }
  
  p{
    font-size: x-small;
  }
  
  nav{
    margin: auto;
    text-align: center;
  }

  #Logo{
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .headerlogo{
    padding: 0;
    width: auto;
  }

  #headermenu{
    display: none;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(181, 171, 158, 0.8); /* Adjust the last value for transparency */
    z-index: 0; 
  }

  #attention {
    min-height: 500px;
  }

  .attentionContent {
    position: relative;  
    color: rgb(245, 245, 220);
    z-index: 1; 
    padding-left: 0;
}
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  h1{
    font-size: 20;
    margin-top: 60px;
  }
  
  h3{
    font-size: large;
    margin: 20px 0 25px 0;
  }
  
  p{
    font-size: x-small;
  }
  
  nav{
    margin: auto;
    text-align: center;
  }

  #Logo{
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .headerlogo{
    padding: 0;
    width: auto;
  }

  #headermenu{
    display: none;
  }
}

/* Desktops, large screens */
/* and (max-width: 1200px) */
@media (min-width: 1025px)  { 
  /*Everything appears to work fine*/  
  #mobile-menu{
    display: none;
  }

  nav{
    
    display: flex;
    justify-content: space-around;
    align-items: center;
  }   
}

/* Extra large screens, TV */
@media (min-width: 1201px) {

  #mobile-menu{
    display: none;
  }

  /* This seems to be both macbook 13" and 16"? */
  nav{
    /* background-color: red; */
    display: flex;
    justify-content: space-around;
    align-items: center;
  } 

  .attentionElements{
    padding-Left: 20%;  
  }
}


