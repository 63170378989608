.burger-menu {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
  }
  
  .burger-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgb(163, 167, 152);
    /* rgba(181, 171, 158, 0.8); */
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
  
  .menu-content {
    display: none;
  }

  #mobile-menu li{
    padding: 5px 5px 5px 5px;
    margin: 5px 5px 5px 5px;
    color: #2c3e50;
  }

  #mobile-menu li:hover{
    color: #e74c3c;
  }
  
  .burger-menu.open .menu-content {
    display: block;
    position: absolute;
    bottom: 70px;
    right: 0;
    background-color: rgb(255, 255, 255, 0.7);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  